@import "modern-variables";
@import "node_modules/bootswatch/sandstone/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootswatch/sandstone/bootswatch";

$fa-font-path: '../fonts/awesome/';
@import "node_modules/font-awesome/scss/font-awesome";

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: url(/img/login.jpg) center top / cover no-repeat;
  color: #dcc2ab;
  font-size: 16px;
  background-color: #271b0d!important;
  cursor: url("/img/cursors/arrow.png"), auto;
}

a,
button,
html input[type="button"],
input[type="reset"],
input[type="submit"],
[role="button"] {
  outline: 0!important;
  cursor: url("/img/cursors/action.png"), auto!important;
}

a {
  color: #d77a02;
}
a:hover, a:focus {
  color: #d77a02;
}
.page-profile {
    padding-top: 100px;
    padding-bottom: 90px;
    background: url(/img/profile.jpg) center top / cover no-repeat;
}

.panel {
    width: 587px;
    background-color: transparent;
    border: 0;
}
.panel-heading {
    font-family: 'TrajanPro3-Bold', serif;
    font-size: 22px!important;
    color: #ddc5b9!important;
    font-weight: bold;
    text-shadow: 0 0 2px black;
    margin-top: 0;
    padding-top: 27px;
    text-align: center;
    width: 587px;
    height: 65px;
    background: url(/img/panel-heading.png) top no-repeat;
    background-color: transparent!important;
    border: 0;
}

.panel-body {
    width: 587px;
    background: url(/img/panel-body.png) bottom no-repeat;
    background-color: transparent;
    border: 0;
    padding: 10px 35px 20px 35px;
}
.panel-body-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 585px;
  padding-right: 10px;
}
.panel--recommended .panel-body-scroll {
  max-height: 532px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #2b2115;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #7a1a0c;
}
::-webkit-scrollbar-corner {
  background-color: #140f0a;
}
.progress-bar {
  background-color: #ddc5b9!important;
}

.panel-body form {
    text-align: center;
    padding: 15px 33px 0 33px;
}
.panel-body p {
  text-align: center;
}

.form-bottom {
  background: url(/img/hr-line.png) top no-repeat;
  height: 40px;
  font-family: 'TrajanPro3-Bold', serif;
  padding: 20px 44px 0 44px;
}
.link-register {
    font-size: 14px;
    float: left;
    color: #ddc5b9!important;
}
.link-forgot {
    font-size: 14px;
    float: right;
    color: #920000!important;
}

//TopBar

.navbar-default {
  position: fixed;
  background-color: transparent;
  border: 0;
  height: 100px;
  background: url(/img/top.png) top repeat-x;
  top: 0;
  width: 100%;
  z-index: 1;
}

.nav {
  margin-top: 37px;
  text-align: center;
  float: none;
}
.nav > li {
  float: none;
  display: inline-block;
}

.server-badge {
  height: 74px;
  padding: 10px 5px 0 5px;
  font-family: 'TrajanPro3-Bold', serif;
  cursor: url("/img/cursors/action.png"), auto!important;
}
.chosen {
    background: url(/img/server-select.png) center no-repeat;
}

.server-badge img {
    float: left;
}
.server-up-line {
    width: 432px;
    height: 30px;
    float: right;
    background: url(/img/hr-line.png) bottom no-repeat;
}
.server-name {
  white-space: nowrap;
  font-size: 21px;
  max-width: 200px;
  overflow: hidden;
  height: 24px;
  float: left;
}
.server-region {
  font-size: 16px;
  height: 20px;
  float: right;
  padding-right: 5px;
  padding-top: 5px;
}
.server-online {
  padding-left: 5px;
  border-left: solid 1px #ddc5b9;
  font-size: 16px;
  height: 18px;
  float: right;
  margin-top: 5px;
}
.offline {
    color: #707070;
}
.hardcore {
    color: #d77a02;
}
.casual {
    color: #9cf600;
}
.pve {
    color: #9cf600;
}
.pvp {
    color: #f40000;
}

.server-bottom-line {
  width: 432px;
  float: right;
}
.server-type {
  float: left;
  font-size: 14px;
  margin-top: 4px;
}
.server-player {
  float: right;
  font-size: 12px;
  margin-top: 6px;
}

.test-line {
  color: #d77a02;
  font-family: 'TrajanPro3-Bold', serif;
  margin-bottom: -5px;
}


.bottom-design {
  position: fixed;
  height: 94px;
  background: url(/img/bottom.png) repeat;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.wt-button-dark-red {
  padding: 0;
  border: 0;
  text-align: center;
  line-height: 53px;
  width: 254px;
  height: 53px;
  font-family: 'TrajanPro3-Bold', serif;
  font-size: 18px;
  color: #ddc5b9;
  font-weight: bold;
  background: url(/img/button-dark-red.png) no-repeat;
  a {
    color: #ddc5b9!important;
    line-height: 53px!important;
    padding: 0!important;
  }
}
.wt-button-red {
  border: 0;
  display: block;
  padding: 0;
  line-height: 53px;
  width: 254px;
  height: 53px;
  font-family: 'TrajanPro3-Bold', serif;
  font-size: 18px;
  color: #ddc5b9!important;
  background-color: transparent!important;
  font-weight: bold;
  background: url(/img/button-red.png) no-repeat;
  margin: 0 auto;
  text-align: center;
  text-decoration: none!important;
}
.connect-button {
  line-height: 26px;
  font-size: 18px;
}

.server-disabled,
button:disabled,
button[disabled]{
  filter: grayscale(100%);
  cursor: url("/img/cursors/arrow.png"), auto!important;
}

.form-group {
  text-align: center;
  min-height: 53px;
  margin-bottom: 20px;
}
.wt-input-field-long {
  width: 432px;
  height: 53px;
  border: none;
  text-align: center;
  color: #e1c5b3!important;
  font-size: 17px!important;
  background-color: transparent!important;
  background-image: url("/img/input-long.png")!important;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.wt-input-field-long:focus {
    outline: 0;
}
.wt-form-button-dark-red {
  line-height: 53px;
  float: right;
  width: 200px;
  height: 53px;
  font-family: 'TrajanPro3-Bold', serif;
  font-size: 18px;
  color: #ddc5b9!important;
  font-weight: bold;
  border: 0;
  background: url(/img/form-dark-red.png) no-repeat;
  text-decoration: none!important;
}
.wt-form-button-red {
  outline: 0;
  line-height: 53px;
  float: left;
  width: 200px;
  height: 53px;
  font-family: 'TrajanPro3-Bold', serif;
  font-size: 18px;
  color: #ddc5b9;
  font-weight: bold;
  border: 0;
  background: url(/img/form-red.png) no-repeat;
}

.panel {
  //Panels with tabs
  &.with-nav-tabs {
    .panel-heading {
      padding-bottom: 0;
    }
    .nav-tabs {
      border-bottom: 0;
    }
  }
  //Server info panel
  &.panel--server {
    .dl-horizontal {
      dt {
        width: 110px;
      }
      dd {
        margin-left: 120px;
      }
    }
    a.btn {
      margin: 10px 0;
    }
  }
  &.panel--recommended {
    .alert.panel--recommended-box {
      height: 120px;
      cursor: pointer;
      &.alert-success:hover {
        background-color: #79a736;
      }
      &.alert-info:hover {
        background-color: #567f0f;
      }
    }
  }
  &.panel--filter {
    .panel-heading {
      padding: 0;
      .panel-title {
        padding: 4px 10px;
        a {
          text-decoration: none;
          display: block;
          &:before {
            @extend .glyphicon;
            float: right;
            top: 5px;
            content: "\e260";
          }
          &.collapsed:before {
            @extend .glyphicon;
            top: 5px;
            content: "\e259";
          }
        }
        a:hover {
          text-decoration: none;
        }
      }
    }
  }
}

//News
.news--item {
  &:not(:last-child) {
    padding-bottom: 30px;
    background-image: url("/img/red-line.png");
    background-position: bottom center;
    background-repeat: no-repeat;
  }
  &:first-child {
    h3 {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 20px;
  }
  .news--content {
    img {
      width: auto;
      height: auto;
      max-width: 200px;
      float: left;
      border: 1px solid #3A3A3A;
      margin-top: 4px;
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }
}

.alert--with-button {
  .pull-right.btn {
    margin-top: -14px;
    margin-right: -33px;
  }
}

// Strateg's part

.navbar {
  padding-top: 5px;
  padding-bottom: 2px;
}

.sandstone {
  font-size: 14px;
}

.tooltip {
  font-size: 11px;
}

.page-header {
  h1 {
    padding: 10px 20px;
    margin: 0;
  }
  background-color: #F8F5F0;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
}

.btn-block {
  .glyphicon,
  .fa {
    margin-right: 11px;
    margin-left: -5px;
  }
}

#userInfo {
  position: fixed;
  top: 12px;
  width: 100%;
  text-align: center;
  color: #dcc2ab;
  z-index: 2;
}

.activeServer {
  left: auto !important;
  right: auto !important;
  top: 97px !important;
}

.ownServerLink {
  float: right !important;
  a {
    color: #93c54b !important;
  }
}

.alert {
  margin-bottom: 0;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.badge {
  border: #bbb 1px solid;
}

.badge-offline {
  background-color: #eeeeee;
  color: black !important;
}

.badge-low {
  background-color: #93C54B;
}

.badge-medium {
  background-color: #c0c54a;
}

.badge-high {
  background-color: #c5b154;
}

#launcher {
  display: none;
}

.alert {
  background-color: transparent!important;
  color: #dcc2ab;
  font-size: 16px;
  text-align: center;
}

.alert-danger {
  color: #920000!important;
  font-size: 20px;
}

.modal-dialog {
  top: 140px;
}

.modal-content {
  width: 587px;
  background-color: transparent;
  border: 0!important;
  box-shadow: none;
}
.modal-header {
  h4 {
      font-family: 'TrajanPro3-Bold', serif;
      font-size: 22px!important;
      color: #ddc5b9!important;
      font-weight: bold;
      text-shadow: 0 0 2px black;
  }
  margin-top: 0;
  padding-top: 27px;
  text-align: center;
  width: 587px;
  height: 65px;
  background: url(/img/panel-heading.png) top no-repeat;
  background-color: transparent!important;
  border: 0!important;
}
.modal-header .close {
  color: transparent;
  width: 33px;
  height: 31px;
  background: url(/img/close.png) no-repeat;
  opacity: 1;
  margin-right: 20px;
}
.modal-header .close:hover {
  background-position-x: -33px;
}

.panel-heading div {
  width: 33px;
  height: 31px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: url("/img/cursors/action.png"), auto!important;
}
.panel-heading div:hover {
  background-position-x: -33px;
}
.private-tab-icon {
  background: url(/img/private-servers.png) no-repeat;
}
.official-tab-icon {
  background: url(/img/official-servers.png) no-repeat;
}

.modal-body {
  width: 587px;
  max-height: 619px;
  overflow: hidden;
  background: url(/img/panel-body.png) bottom no-repeat;
  background-color: transparent;
  border: 0;
  padding: 10px 35px 20px 35px;
}

.modal-body p {
  font-size: 18px;
}

.modal-backdrop.in {
  opacity: 0.7;
}
.tooltip {
  font-size: 16px;
  text-transform: none;
}

.tooltip-inner {
  text-align: left;
}