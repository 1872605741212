$alert-info-bg: #709c22;


/*
@font-face {
  font-family: Bitter-Regular;
  src: url("../fonts/our/bitter-regular.ttf") format("truetype");
}
@font-face {
  font-family: Rex-Bold;
  src: url("../fonts/our/rex-bold.otf") format("opentype");
}
*/
/*
@font-face {
  font-family: TrajanPro3-Regular;
  src: url("../fonts/our/TrajanPro3-Regular.otf") format("opentype");
}
*/
@font-face {
  font-family: TrajanPro3-Bold;
  src: url("../fonts/our/TrajanPro3-Bold.otf") format("opentype");
}

$font-family-sans-serif:  Tahoma, Verdana, Arial, sans-serif;
$font-family-serif:       Tahoma, Verdana, Arial, sans-serif;
$headings-font-family:  TrajanPro3-Bold, Georgia, "Times New Roman", Times, serif;
$tooltip-bg: black;
$tooltip-color: #dcc2ab;
$tooltip-max-width: 550px;
